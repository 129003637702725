<template>
  <div class="culture">
    <img src="../../assets/imges/index/27.png" class="headerimg" />
    <div class="cultureheader">
      <span class="title1">企业文化</span
      ><span class="arrows"><i class="el-icon-arrow-right"></i></span>
      <span class="title2" @click.prevent="custormAnchor('cultureinfo')"
        >企业目标</span
      >
      <span class="title2" @click.prevent="custormAnchor('spirit')"
        >企业精神</span
      >
      <span class="title2" @click.prevent="custormAnchor('purpose')"
        >企业宗旨</span
      >
      <span class="title2" @click.prevent="custormAnchor('spirit2')"
        >企业理念</span
      >
      <span class="title2" @click.prevent="custormAnchor('construction')"
        >团队建设</span
      >
    </div>
    <div class="cultureinfo" id="cultureinfo">
      <div class="target">
        <div class="header">企业目标</div>
        <div class="targetcard">
          <img src="../../assets/culture/1.png" class="targetimg" />
          <div class="targetinfo">
            <div>
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">企业使命</div>
                <div class="info">让世界变得更智慧</div>
              </div>
            </div>
            <div class="center">
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">企业愿景</div>
                <div class="info">成为互联网行业先导者</div>
              </div>
            </div>
            <div>
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">企业价值观</div>
                <div class="info">
                  以人为本，以质取胜<br />以勤为业，以新求变
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="spirit" id="spirit">
      <div class="target">
        <div class="header">企业精神</div>
        <div class="targetcard">
          <div class="targetinfo">
            <div>
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">愚公精神</div>
                <div class="info">
                  直面问题<span>知难而退</span><br />锲而不舍<span
                    >持之以恒</span
                  >
                </div>
              </div>
            </div>
            <div class="center">
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">工匠精神</div>
                <div class="info">
                  精益求精<span>追求卓越</span><br />戒骄戒躁<span
                    >平心静气</span
                  >
                </div>
              </div>
            </div>
            <div>
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">安危精神</div>
                <div class="info">
                  逆水行舟<span>不进则退</span><br />居安思危<span
                    >自强不息</span
                  >
                </div>
              </div>
            </div>
          </div>
          <img src="../../assets/culture/2.png" class="targetimg" />
        </div>
      </div>
    </div>

    <div class="purpose" id="purpose">
      <div class="purposeheader">企业宗旨</div>
      <div class="purposeinfo">
        为客户创造价值
        <span>为企业创造利益</span>
      </div>
      <div class="purposetext">
        为客户创造价值
        <span>为企业创造利益</span>
      </div>
    </div>

    <div class="spirit2" id="spirit2">
      <div class="target">
        <div class="header">企业理念</div>
        <div class="targetcard">
          <img src="../../assets/culture/4.png" class="targetimg" />
          <div class="targetinfo">
            <div>
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">创新理念</div>
                <div class="info">
                  打破常规<span>突破进取</span><br />创新求变<span
                    >敢为人先</span
                  >
                </div>
              </div>
            </div>
            <div class="center">
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">管理理念</div>
                <div class="info">
                  打破常规<span>突破进取</span><br />创新求变<span
                    >敢为人先</span
                  >
                </div>
              </div>
            </div>
            <div>
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">服务理念</div>
                <div class="info">一切为了客户，为了客户一切，为了一切客户</div>
              </div>
            </div>
          </div>
          <div class="targetinfo2">
            <div>
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">团队理念</div>
                <div class="info">
                  团结友爱<span>不惧难关</span><br />协作共赢<span
                    >齐头并进</span
                  >
                </div>
              </div>
            </div>
            <div class="center">
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">品质理念</div>
                <div class="info">
                  高标准<span>高要求 </span><br />高效率<span>高品质</span>
                </div>
              </div>
            </div>
            <div>
              <img src="../../assets/culture/6.png" />
              <div class="targettext">
                <div class="targettexttitle">人才理念</div>
                <div class="info">看文聘更看能力，看能力更看奉献</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="construction" id="construction">
      <div class="target">
        <div class="header">团队建设</div>
        <div class="targetcard">
          <div class="targetinfo">
            <div class="targetinfoheader">
              <div class="targettitle">团队信息</div>
              <div class="targettitle2">Team information</div>
            </div>
            <div class="targetinfotext">
              <div class="text1">
                {{ teaminfo.teamDesc }}
              </div>
              <!--	<div>
								邦伲德立足于互联网领域，依托强大的云计算研发实力，面向制造、金融、政务、交通、医疗、电信、等众多领域提供包括一站式源码定制开发、电商托管、新媒体代运营、多维度品牌推广等各种优质服务，帮助企业定制打造解决方案。
							</div> -->
            </div>
          </div>
          <el-image :src="teaminfo.teamPicture" class="targetimg">
            <div slot="error" class="image-slot">
              <img src="../../assets/imges/error/2.png" class="errimg" />
            </div>
          </el-image>
        </div>
      </div>
    </div>

    <div class="kernelteam" id="kernelteam">
      <div class="teamlist">
        <div class="teamheader">高管</div>
        <div class="teaminfo">
          <div class="teamcard" v-for="(item, id) in teamLsit" :key="id">
            <div class="name">
              <div class="nametext nameq">{{ item.name }}</div>
              <div class="nametext nameblock"></div>
              <img :src="item.picture" />
            </div>
            <div class="info">
              {{ item.position }}<br />
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
      <div class="teamlist teambottom">
        <div class="teamheader">核心团队</div>
        <div class="teaminfo">
          <div class="teamcard" v-for="(item, id) in teamLsit2" :key="id">
            <div class="name">
              <div class="nametext nameq">{{ item.name }}</div>
              <div class="nametext nameblock"></div>
              <img :src="item.picture" />
            </div>
            <div class="info">
              {{ item.position }}<br />
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
      <div class="teamlist teambottom">
        <div class="teamheader" v-if="teamLsit3.length > 0">顾问团队</div>
        <div class="teaminfo">
          <div class="teamcard" v-for="(item, id) in teamLsit3" :key="id">
            <div class="name">
              <div class="nametext nameq">{{ item.name }}</div>
              <div class="nametext nameblock"></div>
              <img :src="item.picture" />
            </div>
            <div class="info">
              {{ item.position }}<br />
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TeamBuildingQuery, TeamList } from "../../api/index.js";
export default {
  data() {
    return {
      teaminfo: {},
      teamLsit: [
        {
          id: 1,
          name: "陈震",
          post: "首席营销官",
          info: "商业化产品大咖",
          attr: require("../../assets/imges/culture/1.png"),
        },
        {
          id: 2,
          name: "刘仁秀",
          post: "首席运营官",
          info: "擅长全链路运营实战",
          attr: require("../../assets/imges/culture/2.png"),
        },
        {
          id: 3,
          name: "陈志远",
          post: "首席技术官",
          info: "精通数据化开发和管理",
          attr: require("../../assets/imges/culture/3.png"),
        },
        {
          id: 4,
          name: "张珍珍",
          post: "首席财务官",
          info: "优秀的财务处理和管理能力",
          attr: require("../../assets/imges/culture/4.png"),
        },
      ],
      teamLsit2: [
        {
          id: 5,
          name: "张竞文",
          post: "产品经理",
          info: "擅长从0-1产品搭建",
          attr: require("../../assets/imges/culture/5.png"),
        },
        {
          id: 6,
          name: "张阳",
          post: "企划经理",
          info: "资深项目策划整合营销专家",
          attr: require("../../assets/imges/culture/6.png"),
        },
        {
          id: 7,
          name: "仇宇",
          post: "渠道经理",
          info: "专业的行业资源整合运营能力",
          attr: require("../../assets/imges/culture/7.png"),
        },
        {
          id: 8,
          name: "江凤",
          post: "电商经理",
          info: "网红直播电商行业专家",
          attr: require("../../assets/imges/culture/8.png"),
        },
      ],
      teamLsit3: [],
    };
  },
  mounted() {
    TeamList({
      TeamTypeId: 1,
    }).then((res) => {
      this.teamLsit = res.data;
    });
    TeamList({
      TeamTypeId: 2,
    }).then((res) => {
      this.teamLsit2 = res.data;
    });
    TeamList({
      TeamTypeId: 3,
    }).then((res) => {
      this.teamLsit3 = res.data;
    });
    TeamBuildingQuery().then((res) => {
      this.teaminfo = res.data[0];
    });
  },
  methods: {
    custormAnchor(index) {
      //锚点滚动到固定位置
      let anchorElement = document.getElementById(index);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
  },
};
</script>

<style scoped lang="less">
a {
  text-decoration: none;
}

.targettexttitle {
  font-size: 28px;
  font-family: Medium;
  font-weight: 500;
}

.header {
  text-align: center;
  font-size: 36px;
  font-family: Medium;
  font-weight: 500;
  padding-top: 78px;
  margin-bottom: 61px;
}

.kernelteam {
  width: 80%;
  margin: 0 auto;
  padding: 102px 0;

  .teamlist {
    .teamheader {
      font-size: 35px;
      font-family: Medium;
      font-weight: 500;
    }

    .teaminfo {
      display: flex;
      justify-content: center;
      margin-top: 45px;

      .teamcard:hover {
        background: #f2f3f5;
      }

      .teamcard {
        width: 491px;
        height: 406px;
        padding-left: 47px;
        background: #fbfbfb;

        .name {
          font-size: 32px;
          font-family: Medium;
          font-weight: 500;
          color: #1c81ff;

          .nametext {
            display: inline-block;
            margin-top: 121px;
          }

          .nameq {
            width: 134px;
          }

          .nameblock {
            position: absolute;
            width: 36px;
            height: 36px;
            margin-left: 40px;
            background-color: #1c81ff;
          }

          img {
            width: 144px;
            height: 144px;
            float: right;
          }
        }

        .info {
          margin-top: 19px;
          width: 300px;
          font-size: 20px;
          font-family: Medium;
          font-weight: 500;
          color: #828282;
          line-height: 42px;
        }
      }
    }
  }

  .teambottom {
    margin-top: 52px;
  }
}

.construction {
  background: #f5f5f9;

  .target {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 107px;

    .targetcard {
      display: flex;
      justify-content: center;

      .targetinfo {
        .targetinfoheader {
          .targettitle {
            text-align: left;
            padding-left: 16px;
            border-left: 4px solid #2f7ffc;
            font-size: 35px;
            font-family: Medium;
            font-weight: 500;
          }

          .targettitle2 {
            text-align: left;
            padding-left: 16px;
            border-left: 4px solid #ffda0a;
            font-size: 20px;
            font-family: Medium;
            font-weight: 500;
            color: #858585;
            line-height: 33px;
          }
        }

        .targetinfotext {
          width: 640px;
          font-size: 18px;
          font-family: Medium;
          font-weight: 500;
          color: #828282;
          line-height: 28px;

          .text1 {
            margin-top: 50px;
            margin-bottom: 25px;
          }
        }
      }

      .targetimg {
        width: 651px;
        height: 495px;
        margin-left: 109px;
      }
    }
  }
}

.spirit2 {
  width: 80%;
  margin: 0 auto;

  .target {
    padding-bottom: 107px;

    .targetcard {
      display: flex;
      justify-content: center;

      .targetimg {
        width: 651px;
        height: 495px;
      }

      .targetinfo {
        margin-left: 154px;

        span {
          margin-left: 30px;
        }

        img {
          width: 21px;
          vertical-align: top;
          margin-top: 12px;
        }

        .center {
          margin: 25px 0;
        }

        .targettext {
          margin-left: 27px;
          display: inline-block;

          .info {
            width: 268px;
            font-size: 24px;
            font-family: Medium;
            font-weight: 500;
            color: #9b9c9d;
            margin-top: 20px;
            line-height: 42px;
          }
        }
      }

      .targetinfo2 {
        margin-left: 46px;

        span {
          margin-left: 30px;
        }

        img {
          width: 21px;
          vertical-align: top;
          margin-top: 12px;
        }

        .center {
          margin: 25px 0;
        }

        .targettext {
          margin-left: 27px;
          display: inline-block;

          .info {
            width: 268px;
            font-size: 24px;
            font-family: Medium;
            font-weight: 500;
            color: #9b9c9d;
            margin-top: 20px;
            line-height: 42px;
          }
        }
      }
    }
  }
}

.purpose {
  background-image: url(../../assets/culture/3.png);
  background-size: contain;
  height: 442px;
  text-align: center;
  color: #fff;
  font-size: 36px;
  font-family: Medium;
  font-weight: 500;

  span {
    margin-left: 293px;
  }

  .purposeheader {
    padding-top: 102px;
  }

  .purposeinfo {
    margin-top: 68px;
  }

  .purposetext {
    margin-top: 28px;
  }
}

.spirit {
  background-color: #f5f5f9;

  .target {
    padding-bottom: 107px;
    width: 80%;
    margin: 0 auto;

    .targetcard {
      display: flex;
      justify-content: center;

      .targetimg {
        width: 872px;
        height: 480px;
        margin-left: 154px;
      }

      .targetinfo {
        img {
          width: 21px;
          vertical-align: top;
          margin-top: 12px;
        }

        .center {
          margin: 25px 0;
        }

        .targettext {
          margin-left: 27px;
          display: inline-block;

          .title {
            font-size: 28px;
            font-family: Medium;
            font-weight: 500;
          }

          .info {
            font-size: 24px;
            font-family: Medium;
            font-weight: 500;
            color: #9b9c9d;
            margin-top: 20px;
            line-height: 42px;

            span {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
}

.culture {
  .cultureheader {
    width: 80%;
    margin: 0 auto;
    padding: 59px 0;
    font-size: 28px;
    border-bottom: 1px solid #979797;

    .title1 {
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
    }

    .arrows {
      color: #bebebe;
      padding-left: 25px;
    }

    .title2 {
      font-weight: 500;
      margin-left: 97px;
      color: #b4b4b4;
    }

    .title2:hover {
      color: #2f7ffc;
      padding-bottom: 15px;
      border-bottom: 6px solid #2f7ffc;
      cursor: pointer;
    }
  }

  .cultureinfo {
    width: 80%;
    margin: 0 auto;

    .target {
      padding-bottom: 107px;

      .targetcard {
        display: flex;
        justify-content: center;

        .targetimg {
          width: 872px;
          height: 480px;
        }

        .targetinfo {
          margin-left: 154px;

          img {
            width: 21px;
            vertical-align: top;
            margin-top: 12px;
          }

          .center {
            margin: 25px 0;
          }

          .targettext {
            margin-left: 27px;
            display: inline-block;

            .title {
              font-size: 28px;
              font-family: Medium;
              font-weight: 500;
            }

            .info {
              font-size: 24px;
              font-family: Medium;
              font-weight: 500;
              color: #9b9c9d;
              margin-top: 20px;
              line-height: 42px;
            }
          }
        }
      }
    }
  }
}
</style>
